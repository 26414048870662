import React from "react";
import { Stack, Typography } from "@mui/material";
import { Trans } from "@lingui/macro";
import Container from "../../components/Container";
import NewLayout from "../../components/NewLayout";
import Map from "../../components/Map";
import Timeline from "../../components/Timeline";

function OurHistoryPage() {
  return (
    <NewLayout byDefault apps="about.about_history">
      <Container pb={{ xs: 4, sm: 8 }}>
        <Typography mt={3} sx={{ mb: { xs: 4, sm: 8 } }} variant="h2">
          <Trans>Our History</Trans>
        </Typography>
        <Timeline />
        <Stack
          mt={{ xs: 5, sm: 10 }}
          spacing={2}
          sx={{ maxWidth: { xs: "100%", sm: "70%" } }}
        >
          <Typography variant="h2">
            <Trans>Global Presence</Trans>
          </Typography>
          <Typography>
            <Trans>
              Allfunds proudly serves the global community as a trusted source
              for innovative and reliable WealthTech products, as well as,
              highly accessible service and support. We work seamlessly with our
              customers regardless of geographic location, language or
              requirements. Our commitment to delivering value for our customers
              around the globe has enabled us to understand and appreciate their
              unique needs.
            </Trans>
          </Typography>
        </Stack>
      </Container>
      <Map />
    </NewLayout>
  );
}

export default OurHistoryPage;
